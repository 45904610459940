@import 'styles/theme/theme';

.socialLoginButtonsWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.socialIcon {
  margin-right: 8px;
  display: flex;
  img {
    width: 18px;
  }
}

.linkButton {
  font-size: 14px;
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: 1px solid $borderGrey;
  border-radius: 4px;
  height: 42px;
  color: $primaryGrey;
  margin-bottom: 16px;
  text-transform: capitalize;
  cursor: pointer;
  width: 100%;
  width: 100%;
  background-color: $backgroundGrey;
  &:hover {
    color: $blue;
    text-decoration: none;
    background-color: $backgroundGrey;
    border: 1px solid $blue;
  }
}

.buttonText {
  display: flex;
  align-items: center;
}
.forwardIcon {
  fill: $blue;
}
